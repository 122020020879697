@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.pageContainer {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  font-family: sans-serif;
}

.homepageH1 {
  font-size: 3.7rem;
  font-weight: 700;
  font-family: sans-serif;
  color: #f65a00;
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: 0.03rem;
}

h2 {
  font-size: 1.5rem;
  font-weight: 700;
  color: black;
  margin-top: 5px;
  margin-bottom: 0;
  margin-left: 3px;
  letter-spacing: 0.03rem;
}

.weekName {
  margin-top: 20px;
}

p {
  font-size: 1.3rem;
  font-weight: 600;
  color: black;
  margin-top: 6px;
  margin-bottom: 0;
  margin-left: 5px;
}

hr {
  margin-top: 15px;
  /* width: 50%; */
  max-width: 760px;
  margin-left: 0;
}

ul {
  list-style-type: none;
  margin: 15px 5px;
  padding: 0;
  max-width: 760px;
}

li {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.2;
  color: black;
  margin-top: 15px;
  margin-bottom: 0;
  margin-left: 5px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgb(159, 159, 159);
}

li a {
  display: inline-block;
  max-width: 645px;
}

a {
  color: #0375bc;
  text-decoration: none;
}

a:visited {
  color: #898989 !important;
}

a:hover {
  color: #15a9d1 !important;
}

@media screen and (max-width: 768px) {
  .pageContainer {
    padding: 7px;
  }
  .homepageH1 {
    font-size: 2.7rem;
  }
  /* h2 {
    font-size: 1.3rem;
  }
  p {
    font-size: 1rem;
  }
  li {
    font-size: 1rem;
  }
  li a {
    max-width: 100%;
  } */
}

@media screen and (max-width: 582px) {
  .homepageH1 {
    font-size: 2.2rem;
  }
  h2 {
    font-size: 1.4rem;
    color: #3d3d3d;
  }
}
